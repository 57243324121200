import React, { useState } from 'react';
import Link from 'next/link';
import { CollectionComponentProps, CollectionComponentStateProps } from '../../types/pages/products/CollectionsTypes';
import { SubscriptionDetail } from '../../types/api/SubscriptionTypes';
import { ApplicationState } from '../../types/state/storeTypes';
import VideoCover from 'react-video-cover';
import { clientSideLogger as logger } from '../../shared/libs/ErrorLogger';
import { CheckmarkIcon } from '../../modules/Icons';
import TagContainer from '../shared/TagContainer';
import AddToWishlistButton from '../wishlists/AddToWishlistButton';
import { Tooltip } from 'react-tooltip';
import { ENV } from '../../constants/environments';
import CollectSearchResultsPlugin from '../user-interest-analytics/CollectSearchResultsPlugin';
import { CollectionItemDetails } from '../shared/CollectionItemDetails';
import { connect } from 'react-redux';
import subscriptionHelpers from '../../helpers/subscriptionHelpers';

import {
  getCollectionLinkHref,
  getCollectionPath
} from '../../helpers/pages/collections/collectionsHelpers';
import { AssetType3DContainer } from '../shared/AssetType3DContainer';

const Collection: React.FC<CollectionComponentProps> = (props) => {
  const {
    name,
    media,
    freeProduct,
    varietyPack,
    elements,
    slug,
    id,
    wishlistRemove,
    contextWishlistId,
    membershipTier,
    library,
    ownership,
    assetTypes,
    onProductRemovedFromWishlist = (product: any) => null,
    contributor,
    hideAddToWishlistUi,
    subscription,
    essentialsMonthlyExpiryDate,
  } = props;
  const { isLegacy } = subscriptionHelpers;

  const [hovered, setHovered] = useState(false);
  const [addToWishlistMenuOpen, setAddToWishlistMenuOpen] = useState(false);
  const [addToWishlistButtonVisible, setAddToWishlistButtonVisible] = useState(true);

  const newRelease = props.new;

  const getProductType = (): '2d' | '3d' | 'pfl' => {
    if (library?.id === 3) return '3d';
    if (library?.id === 2) return 'pfl';
    if (library?.id === 1) return '2d';
  };

  let videoRef: any = React.createRef<HTMLVideoElement>();
  let previewPlaying: boolean = false;
  let validPreview: boolean = Boolean(media.mp4);
  const videoOptions = {
    src: media.mp4,
    muted: true,
    playsInline: true,
    preload: 'none',
    loop: true,
    ref: (videoElem) => {
      videoRef = videoElem;
    },
    'data-testid': 'collectionVideoPreview'
  };

  async function onHover(): Promise<void> {
    setHovered(true);
    setAddToWishlistButtonVisible(true);
    if (!validPreview) return null;
    if (!videoRef) return null;
    try {
      videoRef.classList.remove('hidden');
      await videoRef.play();
      previewPlaying = true;
    } catch (err) {
      logger.warning(err);
      previewPlaying = false;
    }
  }

  function onLeave(): void {
    setHovered(false);
    if (!videoRef) return null;
    if (!validPreview) return null;
    videoRef.classList.add('hidden');
    videoRef.currentTime = 0;
    if (previewPlaying) videoRef.pause();
    previewPlaying = false;
  }

  function onAddToWishlistLoginDialog() {
    setAddToWishlistButtonVisible(false);
  }

  function shouldDisplayOwnedUI(): boolean {
    return !isLegacy(subscription) &&
           ownership &&
           membershipTier !== 'silver' &&
          !freeProduct ||
          isLegacy(subscription) && ownership && !freeProduct ||
          !subscription && ownership && membershipTier === 'silver';
  }

  return (
    <Link
      className="max-w-[600px] text-a-lighter-gray hover:text-white stroke-a-lighter-gray hover:stroke-white fill-a-lighter-gray hover:fill-white"
      href={getCollectionLinkHref(slug, library)}
      as={getCollectionPath(slug, library)}
      data-testid="collectionLink"
      prefetch={false}>
      <div
        className="rounded-[5px] relative border border-transparent hover:border-[#4D5457] hover:shadow-md"
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        data-testid="collectionCard">
        <img
          src={ENV.uploadsUrl + media.image}
          width="600"
          height="338"
          className="block bg-slate-900 rounded-[5px]"
          alt={name}
          loading="lazy"
        />
        <div className="absolute top-0 h-[100%] w-[100%] rounded-[5px] overflow-hidden">
          {validPreview && <VideoCover videoOptions={videoOptions} />}
        </div>

        {assetTypes?.length > 0 && (
          <div className="absolute bottom-[12px] left-[12px] stroke-none">
            <AssetType3DContainer assetTypes={assetTypes} />
          </div>
        )}

        <TagContainer
          newRelease={newRelease}
          varietyPack={varietyPack}
          free={freeProduct}
          membershipTier={membershipTier}
          containerClasses={
            'absolute flex gap-1 top-[12px] left-[12px] text-white text-[10px] uppercase'
          }
          essentialsMonthlyExpiryDate={essentialsMonthlyExpiryDate}
        />

        {(hovered || addToWishlistMenuOpen) && !hideAddToWishlistUi && (
          <AddToWishlistButton
            className={`absolute bottom-3 right-3 z-[10] bg-[rgba(19,23,35,0.3)] rounded-[3px] p-1 stroke-white w-[29px] h-[29px] ${
              !addToWishlistButtonVisible && 'invisible'
            }`}
            popoverClassName="right-0 md:left-0 w-max md:ml-[-1]"
            type={wishlistRemove ? 'remove' : 'icon'}
            contextWishlistId={contextWishlistId}
            product={props}
            onWishlistDropdownToggle={(value: boolean) => setAddToWishlistMenuOpen(value)}
            onProductRemovedFromWishlist={(product: any) => onProductRemovedFromWishlist(product)}
            onLoginDialogOpen={() => onAddToWishlistLoginDialog()}
          />
        )}

        {shouldDisplayOwnedUI() && (
          <div className="absolute top-[8px] right-[8px] xl:top-[12px] xl:right-[12px] flex items-center p-1 rounded-[5px] bg-[rgba(0,0,0,.6)]">
            {ownership.owned_elements == ownership.total_elements ? (
              <CheckmarkIcon
                className="block w-3 h-3 xl:w-4 xl:h-4 stroke-a-green"
                data-tooltip-id={`${id}-ownership`}
                data-tooltip-content="Fully owned"
              />
            ) : (
              <CheckmarkIcon
                className="block w-3 h-3 xl:w-4 xl:h-4"
                color={'white'}
                data-tooltip-id={`${id}-ownership`}
                data-tooltip-content="Partially owned"
              />
            )}
            <Tooltip id={`${id}-ownership`} opacity={1} style={{ backgroundColor: 'black' }} />
          </div>
        )}
      </div>

      <CollectionItemDetails
        name={name}
        elements={elements}
        contributor={contributor}
        hovered={hovered}
      />

      {/* placing the plugin at the bottom to make sure all of the collection is in view before reported as seen by the user*/}
      <CollectSearchResultsPlugin
        collection={{ id, name }}
        libraryId={getProductType()}
        mode="productsPageSearch"
      />
    </Link>
  );
};

const mapStateToProps = (state: ApplicationState): CollectionComponentStateProps => ({
  subscription: state.auth.subscription as SubscriptionDetail,
});

export default connect(mapStateToProps)(Collection);
